import React from 'react';
import steamLogoWhite from '../../images/spring19/steamLogoWhite.png'
import TrailerVideo from './TrailerVideo'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { getSteamLink } from './getSteamLink'

const SteamPageMainContent = () => (

  <div className="SteamPageMainContentWrap">
    <div className="SteamPageMainContent">
      <aside className={"SteamPageMainContent_video-aside"}>
        <TrailerVideo />
      </aside>
      <aside className={"SteamPageMainContent_words-aside"}>
        <p>
          <OutboundLink className="SteamPageMainContent_copy-title" target={"_blank"} href={getSteamLink("SteamLpTitleText")}>Danger Crew</OutboundLink> is a retro-style RPG <br/> in a world where programmers battle using their laptops.
        </p>
        <p className="SteamPageMainContent_copy-bold">
          Fans of EarthBound, Super Mario RPG, Pokemon, and programmer humor will enjoy!
        </p>
        <div className="SteamPagePriceContainer">
          <div style={{lineHeight: "18px"}}>
            LIMITED TIME SALE
          </div>
          <div>
            <span className="crossout">$9.99</span><span>(25% OFF)</span>
            <div className="SteamPageMainContent_mega-price">
              <span>$7</span><span className="SteamPageMainContent_mega-price_cents">.49</span>
            </div>
          </div>
        </div>
        <div>
          <OutboundLink className="SteamPageSteamButton" target={"_blank"} href={getSteamLink("SteamLpCta")}>
            <img src={steamLogoWhite} alt="Steam" className="SteamPageSteamButton_img" />
            Buy Danger Crew on Steam
          </OutboundLink>
          <div className="SteamPageSteamButtonDisclaimer">Available on <strong>Windows</strong> and <strong>Mac</strong>!</div>
        </div>
      </aside>
    </div>
  </div>
);

export default SteamPageMainContent
