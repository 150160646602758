import React from 'react'
import video from './dangercrew-steampage-video.mp4'

class TrailerVideo extends React.Component {

  componentDidMount() {
      document.getElementById('trailervideo').addEventListener('loadedmetadata', function() {
        this.currentTime = 5; //Start trailer a few seconds in
      }, false);
  }

  render() {
    return (
      <video id="trailervideo" autoPlay="autoplay" muted loop>
        <source src={video} type="video/mp4" />
      </video>
    )
  }
}

export default TrailerVideo