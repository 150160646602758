import React from 'react'
import logo from './DangerCrewTransparentLogo.png'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import {getSteamLink} from './getSteamLink'

const Header = () => (
  <header className="SteamPage_header">
    <OutboundLink className="SteamPage_header_top-link" href={getSteamLink("SteamLpHeaderLogo")} target={"_blank"} title={"Danger Crew on Steam"}>
      <img src={logo} alt={"Danger Crew"} />
    </OutboundLink>
  </header>
);

export default Header
