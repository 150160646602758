import React from 'react';
import emailImage from './EmailModulePeople.png'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const SteamPageEmailBlock = function() {
  return (
    <div className="SteamPage_email-box">
      <div className="SteamPage_email-box-content">
        <aside className="SteamPage_email-characters-column">
          <img src={emailImage} alt="characters from Danger Crew" />
        </aside>
        <main className="SteamPage_email-box-copy">
          <h2>Play Now for Free</h2>
          <p>Join our newsletter to receive the first 2 chapters of Danger Crew for free!</p>
          <div>
            <SteamPageEmailSignup />
          </div>
        </main>
      </div>
    </div>
  )
}

const SteamPageEmailSignup = () => (
    <form className="SteamEmailBlock" onSubmit={handleSubmitNewsletterForm}>
      <label htmlFor="email-input">Email Address</label>
      <div className="SteamEmailBlock__FormContainer">
        <input autoFocus={true} className="SteamEmailBlock__TextInput" id="email-input" type="email" placeholder="you@email.com" />
        <input className="SteamEmailBlock__SubmitButton" type="submit" value="Play Now" />
      </div>
      <p
        className="SteamEmailBlock__Success"
        style={{
        display: "none",
        color: "#18A434",
        fontWeight: "bold"
      }}>Thanks for signing up! Redirecting you to Danger Crew...</p>
      <div className="SteamEmailBlock__Disclaimer">
       We only send occasional update emails. No spam, ever.
      </div>
    </form>
)

function handleSubmitNewsletterForm(e) {
  if (e) {
    e.preventDefault();
  }

  const email = document.querySelector("#email-input").value;

  if (email.length > 0) {
    //sign up
    //see docs (https://www.gatsbyjs.org/packages/gatsby-plugin-mailchimp/)
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // but you can do whatever you want (including ignoring this `then()` altogether)
        // TODO: redirect to /steam-email-success page


        if (window.ga) {
          window.ga('send', 'event', 'Newsletter', 'signup', email);
        }

        //if (data.result === "success") {
          document.querySelector(".SteamEmailBlock__TextInput").value = ""
          document.querySelector(".SteamEmailBlock__Success").style.display = "block";
          setTimeout(() => {
            window.location = "https://play.thedangercrew.com"
          }, 1000)
        //}

      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
      })
  }
}

export default SteamPageEmailBlock;