import React from 'react'
import Layout from '../components/Layout'
import '../components/SteamPage/steam.css'
import SteamPageHeader from '../components/SteamPage/SteamHeader'
import SteamPageMainContent from '../components/SteamPage/SteamPageMainContent'
import SteamPageEmailBlock from '../components/SteamPage/EmailBlock'
import styled from 'styled-components'
import starbackground from '../images/spring19/starbackground1.png'

const BackgroundGradient = styled.div`
  width: 100%;
  height: 50vw;
  max-height:800px;
  background-image: linear-gradient(-180deg, #CAEAFF 0%, #FFFFFF 100%);
  left:0;
  top:0;
  margin-top:70px;
  position: absolute;
  z-index:-1;
  
`
const BackgroundStars = styled.div`
  position: absolute;
  left:0; top:0; bottom:0; right: 0;
  background-image: url(${starbackground});
  background-position: center center;
  background-repeat: repeat no-repeat;
  opacity: 0.4;
`


const BackgroundShape = () => {
  return (
    <BackgroundGradient>
      <BackgroundStars />
    </BackgroundGradient>
  )
}



const SteamPage = () => (
  <Layout>
    <main style={{
      position: "relative",
      paddingBottom: 100,
      minHeight: "100vh"
    }}>
      <BackgroundShape />
      <SteamPageHeader />
      <SteamPageMainContent />

      <SteamPageEmailBlock />

    <footer style={{
      position: "absolute",
      height: "100px",
      bottom:0,
      left:0,
      right:0,
      background: "#F0F2F6",
      borderTop: "1px solid #D7D7D1",
      color: "#6A6A70",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      ©2019 Team Danger Crew. All Rights Reserved
    </footer>
    </main>
  </Layout>
);

export default SteamPage
